<template>
  <div>
    <p class="mb-2">
      <strong>Tuotteita </strong>
      <strong>{{ totalQuantity }} kpl</strong>
    </p>

    <p :style="{ 'max-width': maxWidth + 'px' }" class="product-wrapper">
      <strong>Yhteensä ilman alv</strong><strong>{{ formatCurrency(totalSum) }}</strong>
    </p>

    <p
      v-if="totalVatObj['vat10'] != 0"
      :style="{ 'max-width': maxWidth + 'px' }"
      class="product-wrapper"
    >
      <strong>Arvonlisävero 10 %</strong>
      <strong> {{ formatCurrency(totalVatObj["vat10"]) }}</strong>
    </p>

    <p
      v-if="totalVatObj['vat14'] != 0"
      :style="{ 'max-width': maxWidth + 'px' }"
      class="product-wrapper"
    >
      <strong>Arvonlisävero 14 %</strong
      ><strong> {{ formatCurrency(totalVatObj["vat14"]) }}</strong>
    </p>

    <p
      v-if="totalVatObj['vat24'] != 0"
      :style="{ 'max-width': maxWidth + 'px' }"
      class="product-wrapper"
    >
      <strong>Arvonlisävero 24 %</strong><strong>{{ formatCurrency(totalVatObj["vat24"]) }}</strong>
    </p>

    <p
      v-if="totalVatObj['vat255'] != 0"
      :style="{ 'max-width': maxWidth + 'px' }"
      class="product-wrapper"
    >
      <strong>Arvonlisävero 25,5 %</strong
      ><strong>{{ formatCurrency(totalVatObj["vat255"]) }}</strong>
    </p>

    <p :style="{ 'max-width': maxWidth + 'px' }" class="mt-2 success--text product-wrapper">
      <strong>Yhteensä</strong>
      <strong>{{ formatCurrency(totalSumWithVat) }}</strong>
    </p>
  </div>
</template>

<script>
import mixins from "@/mixins/mixins";
import invoiceHelpers from "@/utils/invoiceHelpers";

export default {
  mixins: [mixins],

  props: {
    products: {
      type: Array,
      default: () => {
        return [];
      },
    },
    maxWidth: {
      type: Number,
      default: 280,
    },
  },

  computed: {
    totalQuantity() {
      let sum = 0;
      this.products.forEach((el) => {
        if (el.count < 0 || el.count > 0) sum += Math.abs(el.count);
      });
      return sum;
    },

    totalVatObj() {
      const { vat10, vat14, vat24, vat255 } = invoiceHelpers.calcTotalVats(this.products);
      return { vat10, vat14, vat24, vat255 };
    },

    totalSum() {
      let sum = 0;
      this.products.forEach((el) => {
        if ((el.count < 0 || el.count > 0) && el.amount > 0) sum += el.amount * el.count;
      });
      return sum;
    },

    totalSumWithVat() {
      let sum = 0;
      this.products.forEach((el) => {
        if ((el.count < 0 || el.count > 0) && el.amount > 0)
          sum += el.amount * el.count * (1 + el.taxpr / 100);
      });
      return sum;
    },
  },
};
</script>

<style scoped>
.product-wrapper {
  display: flex;
  align-content: center;
  justify-content: space-between;
}
</style>

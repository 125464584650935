<template>
  <div>
    <div ref="quill_editor" class="quill_editor"></div>
  </div>
</template>

<script>
import Quill from "quill";
import "quill/dist/quill.snow.css";

export default {
  data() {
    return {
      quill: null,
      content: "",
    };
  },

  mounted() {
    const options = {
      theme: "snow",
      modules: {
        toolbar: [
          [{ header: [1, 2, 3, 4, false] }],
          [{ align: [] }],
          [{ list: "ordered" }, { list: "bullet" }],
          ["bold", "italic", "underline"],
        ],
      },
      formats: ["header", "bold", "italic", "underline", "list", "align"],
    };

    this.quill = new Quill(this.$refs.quill_editor, options);

    this.quill.root.style.fontFamily = "'Bai Jamjuree', sans-serif";

    this.quill.on("text-change", () => {
      this.$emit("quillupdate", this.quill.root.innerHTML);
    });
  },

  methods: {
    setContent(html) {
      this.quill.root.innerHTML = html;
    },

    deleteContent() {
      this.quill.setContents([]);
    },
  },
};
</script>

<style>
.quill_editor {
  min-height: 150px;
  overflow-y: auto;
}

.ql-snow .ql-editor h1 {
  font-size: 32px !important;
}

.ql-snow .ql-editor h2 {
  font-size: 26px !important;
}

.ql-snow .ql-editor h3 {
  font-size: 22px !important;
}

.ql-snow .ql-editor h4 {
  font-size: 16px !important;
}

.ql-snow .ql-editor p {
  font-size: 14px !important;
}
</style>

<template>
  <div>
    <h3 class="mb-1">Kustannuspaikka</h3>
    <!-- COSTCENTRE IN USE -->
    <div
      v-if="
        currentUser.currentAccount.settings.useApartmentCostAccounting ||
        currentUser.currentAccount.settings.useCondominiumCostAccounting
      "
    >
      <div>
        <!-- Headline -->
        <p
          v-if="currentUser.currentAccount.settings.useApartmentCostAccounting"
          class="success--text mb-1"
        >
          Vuokrakohdekohtainen kustannuspaikka käytössä
        </p>

        <p v-else class="success--text mb-1">Kiinteistökohtainen kustannuspaikka käytössä</p>

        <!-- On create -->
        <div v-if="costCentre && $route.path.includes('/create')">
          <p>{{ showCostCentre }}</p>
        </div>

        <!-- On edit -->
        <div
          v-else-if="
            (costCentre && ($route.path.includes('/edit') || $route.path.includes('/refund'))) ||
            (costCentre && editRecurrentInvoice)
          "
        >
          <!-- if no currentCostcentre -->

          <div v-if="!currentCostCentre" style="font-size: 14px">
            Nykyistä kustannuspaikkaa ei löydy. Kustannuspaikaksi päivitetään tallennettaessa "{{
              costCentre
            }}"
          </div>

          <!-- if current cost centre is same as real costCentre   -->
          <div v-else-if="costCentre == currentCostCentre">
            <p>{{ showCostCentre }}</p>
          </div>

          <!-- CostCentre is updated from condominium or apartment -->
          <div v-else>
            <p>
              Nykyinen kustannuspaikka laskulla:
              <span class="error--text">{{ showCostCentre }}</span>
            </p>
            <p>
              Kustannuspaikaksi päivitetään tallennettaessa:
              <span class="success--text">{{ costCentre }}</span>
            </p>
          </div>
        </div>

        <!-- No costcentre -->
        <p v-else class="error--text">Kustannuspaikkaa ei löydy</p>
      </div>
    </div>

    <div v-else-if="showCostCentre">
      <p class="error--text">Ei käytössä</p>
      <p style="font-size: 14px">
        Laskun nykyinen kustannuspaikka "{{ showCostCentre }}" poistetaan tallennettaessa.
      </p>
    </div>

    <div v-else>
      <p class="error--text">Ei käytössä</p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["contract", "invoice", "contractLoaded", "invoiceLoaded", "editRecurrentInvoice"],

  data() {
    return {
      currentCostCentre: null,
      costCentre: null,
    };
  },

  computed: {
    ...mapState("account", ["currentUser"]),

    showCostCentre() {
      if (this.currentCostCentre) {
        return this.currentCostCentre;
      } else {
        return this.costCentre;
      }
    },
  },

  watch: {
    invoice() {
      this.init();
    },
    contract() {
      this.init();
    },
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      if (this.$route.path.includes("/create")) {
        if (this.contract) {
          this.costCentre = this.setCostCentre(
            this.currentUser.currentAccount,
            this.contract.apartment.id
          );
        } else {
          this.costCentre = null;
        }
      } else if (
        this.$route.path.includes("/edit") ||
        this.$route.path.includes("/refund") ||
        this.editRecurrentInvoice
      ) {
        if (this.invoice && this.invoice.contract) {
          this.costCentre = this.setCostCentre(
            this.currentUser.currentAccount,
            this.invoice.contract.apartment.id
          );
          // Set only in edit mode
          this.currentCostCentre = this.invoice.costCentre;
        } else {
          this.costCentre = null;
          this.currentCostCentre = null;
        }
      }
    },

    setCostCentre(account, apartment) {
      if (account?.settings?.useCondominiumCostAccounting) {
        if (apartment?.condominium?.costCentre) {
          return apartment.condominium.costCentre;
        } else {
          return null;
        }
      } else if (account?.settings?.useApartmentCostAccounting) {
        if (apartment.costCentre) {
          return apartment.costCentre;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
  },
};
</script>

<style scoped>
p {
  line-height: 17px !important;
  margin-bottom: 6px !important;
  font-size: 15px;
}
</style>
